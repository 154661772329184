import { useState, useEffect } from 'react';
import cotastatsimg from '../../assets/charactersheets/cotastats.png';
//import cotalogo from '../../assets/logos/cotalogo.png';
//import cotatitle from '../../assets/logos/cotatitle.png';
import '../../styles/Page.css';
import CotaCharacterSheet from '../../components/CotaCharacterSheet';
import TopBar from '../../components/TopBar';

export default function Campaign() {
  const [system] = useState(localStorage.getItem('system'));

  const [mode, setMode] = useState('light');

  useEffect(() => {
    const savedData = localStorage.getItem('appearance');
    if (savedData) {
      const { mode } = JSON.parse(savedData);
      setMode(mode);
    }
  }, []);

  useEffect(() => {
    const appearance = {
      mode,
    };
    localStorage.setItem('appearance', JSON.stringify(appearance));
  }, [mode]);

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  return (
    <div className="page_container">
      <div className={'page_top page_top' + darkMode()}>
        <TopBar mode={mode} setMode={setMode} />
      </div>
      <div className="page_content">
        <div className="page_1">
          {system && system === 'trialment' ? (
            <div />
          ) : (
            <CotaCharacterSheet
              mode={mode}
              editable={true}
              onModal={false}
              dmView={false}
              roll={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
