import '../styles/Dice.css';
import { useState, useEffect } from 'react';
import cotastatsimg from '../assets/charactersheets/cotastats.png';
import cotastatsdarkimg from '../assets/charactersheets/cotastatsdark.png';
import cotalogo from '../assets/logos/cotalogo.png';
import enlightenedlogo from '../assets/logos/enlightenedlogo.png';
import honorboundlogo from '../assets/logos/honorboundlogo.png';
import ironsteamlogo from '../assets/logos/ironsteamlogo.png';
import wildheartlogo from '../assets/logos/wildheartlogo.png';
import selectguildlogo from '../assets/logos/selectguildlogo.png';
import DiceBox from '@3d-dice/dice-box';
import '../styles/Cota.css';
import CotaScrollGrid from './CotaScrollGrid';
import CotaRPAbility from './CotaRPAbility';
import { CotaScroll } from './CotaScroll';
import CotaPotion from './CotaPotion';
import { CotaNotes } from './CotaNotes';
import { CotaInventory } from './CotaInventory';
import { CotaGuild } from './CotaGuild';
import { CotaPath } from './CotaPath';

const diceBox = new DiceBox('#dice-box', {
  id: 'dice-canvas',
  assetPath: '/assets/dice-box/',
  startingHeight: 10,
  throwForce: 6,
  spinForce: 5,
  lightIntensity: 0.9,
  scale: 4,
  themeColor: '#e94f37',
});

export default function CotaCharacterSheet({ mode }) {
  const [level, setLevel] = useState('');
  const [characterName, setCharacterName] = useState('');
  const [characterTitle, setCharacterTitle] = useState('');
  const [characterGuild, setCharacterGuild] = useState('None'); // Enlightened, Ηonorbound, Ironsteam, Wildheart, None
  const [characterGuildTitle, setCharacterGuildTitle] = useState('Member'); // Archon, Herald, Ambassador, Librarian, Member
  const [path, setPath] = useState('');
  const [subpath, setSubpath] = useState('');
  const [subpathRPAbilityPlace, setSubpathRPAbilityPlace] = useState('None'); //1, 2, 3, 'None'
  const [temporaryHitPoints, setTemporaryHitPoints] = useState('');
  const [currentHitPoints, setCurrentHitPoints] = useState('');
  const [hitPointsMaximum, setHitPointsMaximum] = useState('');
  const [divineInfluence, setDivineInfluence] = useState('');
  const [agilityDefense, setAgilityDefense] = useState('');
  const [intuitionDefense, setIntuitionDefense] = useState('');
  const [might, setMight] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [divination, setDivination] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [agility, setAgility] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [intuition, setIntuition] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [characterImage, setCharacterImage] = useState('');
  const [declaredScrolls, setDeclaredScrolls] = useState([
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ]);
  const [leveledScrolls, setLeveledScrolls] = useState([]);
  const [selectedScroll, setSelectedScroll] = useState();
  const [showingScroll, setShowingScroll] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showPathSelect, setShowPathSelect] = useState(false);
  const [showGuildSelect, setShowGuildSelect] = useState(false);

  const [isPhone, setIsPhone] = useState(false);
  const [pathOrSubPathSelection, setPathOrSubPathSelection] = useState('path');
  const [levelIsFocused, setLevelIsFocused] = useState(false);

  const openModal = (selected, showing) => {
    setSelectedScroll(selected);
    setShowingScroll(showing);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedScroll();
    setShowingScroll(0);
    setShowModal(false);
  };

  const openNotes = () => {
    setShowNotes(true);
  };

  const closeNotes = () => {
    setShowNotes(false);
  };

  const openInventory = () => {
    setShowInventory(true);
  };

  const closeInventory = () => {
    setShowInventory(false);
  };

  const openPathSelect = (isPathOrSubpath) => {
    setPathOrSubPathSelection(isPathOrSubpath);
    setShowPathSelect(true);
  };

  const closePathSelect = () => {
    setShowPathSelect(false);
    setTimeout(() => {
      setPathOrSubPathSelection('path');
    }, 200); //Time it takes for animation to close
  };

  const openGuildSelect = () => {
    setShowGuildSelect(true);
  };

  const closeGuildSelect = () => {
    setShowGuildSelect(false);
  };

  useEffect(() => {
    const calculateStatCount = (statArray) => {
      return statArray.filter((value) => value).length;
    };
    setHitPointsMaximum(20 + 5 * calculateStatCount(might));
    setIntuitionDefense(`${calculateStatCount(intuition) + 1}d6`);
    setAgilityDefense(`${calculateStatCount(agility) + 1}d6`);
    setDivineInfluence(`${calculateStatCount(divination) + 2}`);
  }, [might, divination, agility, intuition]);

  useEffect(() => {
    diceBox.init();
    const savedImage = localStorage.getItem('characterImage');
    if (savedImage) {
      setCharacterImage(savedImage);
    }
    const savedData = localStorage.getItem('characterData');
    if (savedData) {
      const {
        level,
        characterName,
        characterTitle,
        characterGuild,
        characterGuildTitle,
        path,
        subpath,
        subpathRPAbilityPlace,
        temporaryHitPoints,
        currentHitPoints,
        divineInfluence,
        agilityDefense,
        intuitionDefense,
        might,
        divination,
        agility,
        intuition,
        declaredScrolls,
        leveledScrolls,
      } = JSON.parse(savedData);
      setLevel(level);
      setCharacterName(characterName);
      setCharacterTitle(characterTitle);
      setCharacterGuild(characterGuild);
      setCharacterGuildTitle(characterGuildTitle);
      setPath(path);
      setSubpath(subpath);
      setSubpathRPAbilityPlace(subpathRPAbilityPlace);
      setTemporaryHitPoints(temporaryHitPoints);
      setCurrentHitPoints(currentHitPoints);
      setDivineInfluence(divineInfluence);
      setAgilityDefense(agilityDefense);
      setIntuitionDefense(intuitionDefense);
      setMight(might);
      setDivination(divination);
      setAgility(agility);
      setIntuition(intuition);
      setDeclaredScrolls(declaredScrolls);
      setLeveledScrolls(leveledScrolls);
    }

    const mediaQueryWidth = window.matchMedia('(max-width: 500px)');
    const handleMediaChange = () => {
      setIsPhone(mediaQueryWidth.matches);
    };
    handleMediaChange(); // Check on component mount
    mediaQueryWidth.addListener(handleMediaChange);
    return () => {
      mediaQueryAspectRatio.removeListener(handleMediaChange);
      mediaQueryWidth.removeListener(handleMediaChange);
    };
  }, []);

  useEffect(() => {
    const characterData = {
      level,
      characterName,
      characterTitle,
      characterGuild,
      characterGuildTitle,
      path,
      subpath,
      subpathRPAbilityPlace,
      temporaryHitPoints,
      currentHitPoints,
      divineInfluence,
      agilityDefense,
      intuitionDefense,
      might,
      divination,
      agility,
      intuition,
      declaredScrolls,
      leveledScrolls,
    };
    localStorage.setItem('characterData', JSON.stringify(characterData));
  }, [
    level,
    characterName,
    characterTitle,
    characterGuild,
    characterGuildTitle,
    path,
    subpath,
    subpathRPAbilityPlace,
    temporaryHitPoints,
    currentHitPoints,
    divineInfluence,
    agilityDefense,
    intuitionDefense,
    might,
    divination,
    agility,
    intuition,
    declaredScrolls,
    leveledScrolls,
  ]);

  const handleToggleCotaHex = (index, setState, state) => {
    const newState = [...state];
    newState[index] = !newState[index];
    setState(newState);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      localStorage.setItem('characterImage', reader.result);
      setCharacterImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const rollDice = (stat) => {
    const roll = (diceEnum) => {
      diceBox.roll(diceEnum);
    };
    const addRoll = () => {
      diceBox.add('1d6');
    };
    const calculateDiceCount = (statArray) => {
      return statArray.filter((value) => value).length + 1;
    };
    if (stat === 'might') {
      roll(`${calculateDiceCount(might)}d6`);
    } else if (stat === 'divination') {
      roll(`${calculateDiceCount(divination)}d6`);
    } else if (stat === 'agility') {
      roll(`${calculateDiceCount(agility)}d6`);
    } else if (stat === 'intuition') {
      roll(`${calculateDiceCount(intuition)}d6`);
    } else if (stat === 'divine influence') {
      const divineInfluenceNum = Number(divineInfluence);
      if (
        !isNaN(divineInfluenceNum) &&
        Number.isInteger(divineInfluenceNum) &&
        divineInfluenceNum >= 1
      ) {
        setDivineInfluence(+(divineInfluenceNum - 1));
        addRoll();
      }
    } else {
      roll(`1d6`);
    }
  };

  /*
  const handleLevelInput = (e) => {
    const value = e.target.innerText;
    if (/^\d+$/.test(value) && Number(value) >= 1) {
      setLevel(value);
    } else {
      e.target.innerText = level;
    }
  };

  const handleNonEditableInput = (e, setter, value) => {
    e.target.innerText = value;
  };
  */

  const handlePathChange = (path) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setPath(path);
    setSubpath('');
  };

  const handleSubPathChange = (subpath) => {
    setSubpath(subpath);
  };

  const handleRemoveSubPath = () => {
    setSubpath('');
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setSubpathRPAbilityPlace('None')
  };

  const handleGuildChange = (guild) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setCharacterGuild(guild);
  };

  const handleGuildTitleChange = (guildTitle) => {
    setDeclaredScrolls([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    setLeveledScrolls([]);
    setCharacterGuildTitle(guildTitle);
  };

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return;
  };

  return (
    <div className='page_content'>
      <div className={'cota_character_sheet cota_character_sheet' + darkMode()}>
        <span className='cota_character_sheet_left'>
          <div className='cota_character_sheet_left_whole'>
            <div className='cota_character_page_1 cota_decorative_outline'>
              <div className='cota_stats_spacer' />
              <div className='cota_header_container'>
                <div className='cota_header'>
                  <div
                    className={
                      'cota_stats_level_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    LEVEL
                  </div>
                  <div
                    className={
                      'cota_stats_level cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onFocus={() => setLevelIsFocused(true)}
                    onBlur={(e) => {
                      setLevelIsFocused(false);
                      setLevel(e.target.innerText);

                      const nextLevel = e.target.innerText;
                      const nextLevelIsInteger = Number.isInteger(
                        Number(nextLevel)
                      );

                      if (nextLevelIsInteger) {
                        if (!(nextLevel >= 3)) {
                          handleRemoveSubPath();
                        }
                      } else {
                        handleRemoveSubPath();
                      }
                    }}
                  >
                    {level}
                  </div>

                  {levelIsFocused && level >= 3 && (
                    <div
                      className={
                        'cota_stats_level_warning cota_stats_label cota_stats_label' +
                        darkMode()
                      }
                    >
                      <b>
                        ⚠️ Lowering the Level under 3 or replacing it with a
                        value other than a number will reset your Scrolls!
                      </b>
                    </div>
                  )}
                  <div
                    className={
                      'cota_stats_identity cota_stats_identity' + darkMode()
                    }

                    //onBlur={(e) => setCharacterName(e.target.innerText)}
                  >
                    <div className='cota_img_container'>
                      <img alt='' src={cotalogo} className='cota_img' />
                    </div>
                    <div
                      className=' cota_stats_character_name'
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => setCharacterName(e.target.innerText)}
                    >
                      {characterName}
                    </div>
                    <div
                      className=' cota_stats_character_title'
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={(e) => setCharacterTitle(e.target.innerText)}
                    >
                      {characterTitle}
                    </div>
                    <div
                      className={
                        'cota_house_img_container cota_house_img_container' +
                        darkMode()
                      }
                    >
                      <img
                        alt=''
                        src={
                          characterGuild !== 'None' && characterGuild !== ''
                            ? characterGuild === 'Honorbound'
                              ? honorboundlogo
                              : characterGuild === 'Enlightened'
                              ? enlightenedlogo
                              : characterGuild === 'Wildheart'
                              ? wildheartlogo
                              : ironsteamlogo //if nothing else assume Ironsteam
                            : selectguildlogo
                        }
                        onClick={() => openGuildSelect()}
                        className='cota_house_img'
                      />
                    </div>
                  </div>
                  <div
                    className={
                      'cota_stats_path_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => openPathSelect('path')}
                  >
                    PATH
                  </div>
                  <div
                    className={
                      (path !== '' &&
                      Number.isInteger(Number(level)) &&
                      level >= 3
                        ? `cota_stats_path_subpath_${subpath && subpath !== '' ? 'enabled' : 'select'} `
                        : '') +
                      'cota_stats_path cota_stats_area cota_stats_area' +
                      darkMode() 
                    }
                    //contentEditable
                    //suppressContentEditableWarning
                    onClick={
                      !subpath || subpath === ''
                        ? () => openPathSelect('path')
                        : () => openPathSelect('subpath')
                    }
                    //onBlur={(e) => setPath(e.target.innerText)}
                  >
                    {!subpath || subpath === ''
                      ? path === 'Clockwork Shaman'
                        ? 'C. Shaman'
                        : path
                      : subpath === 'Shadowdancer'
                      ? 'S/dancer'
                      : subpath === 'Inferno Warden'
                      ? 'I. Warden'
                      : subpath}
                  </div>
                  {path !== '' &&
                    Number.isInteger(Number(level)) &&
                    level >= 3 && (
                      <div
                        className={
                          subpath && subpath !== ''
                            ? 'cota_stats_subpath cota_stats_subpath_enabled'
                            : 'cota_stats_subpath cota_stats_subpath' +
                              darkMode()
                        }
                        onClick={() => openPathSelect('subpath')}
                      >
                        SUBPATH
                      </div>
                    )}
                </div>
              </div>
              <div className='cota_stats_spacer' />
              <div className='cota_stats_container'>
                <div className='cota_stats'>
                  <img
                    alt=''
                    src={mode === 'dark' ? cotastatsdarkimg : cotastatsimg}
                    className='cota_stats_image'
                  />
                  <div className='cota_character_image_container'>
                    {characterImage && (
                      <img
                        className='cota_character_image'
                        src={characterImage}
                        alt={''}
                      />
                    )}
                  </div>
                  <div>
                    <input
                      id='cota_character_image_uploader'
                      className='cota_character_image_uploader'
                      type='file'
                      accept='image/*'
                      onChange={handleImageUpload}
                    />
                  </div>
                  <div
                    className={
                      'cota_stats_temporary_hit_points_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Temp HP
                  </div>
                  <div
                    className={
                      'cota_stats_temporary_hit_points cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setTemporaryHitPoints(e.target.innerText)}
                  >
                    {temporaryHitPoints}
                  </div>
                  <div
                    className={
                      'cota_stats_current_hit_points_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Current HP
                  </div>
                  <div
                    className={
                      'cota_stats_current_hit_points cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setCurrentHitPoints(e.target.innerText)}
                  >
                    {currentHitPoints}
                  </div>
                  <div
                    className={
                      'cota_stats_hit_points_maximum_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Max HP
                  </div>
                  <div
                    className={
                      'cota_stats_hit_points_maximum cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setHitPointsMaximum(e.target.innerText)}
                  >
                    {hitPointsMaximum}
                  </div>
                  <div
                    className={
                      'cota_stats_divine_influence_dice cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('divine influence')}
                    >
                      🎲
                    </span>
                  </div>
                  <div
                    className={
                      'cota_stats_divine_influence_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Divine Influence
                  </div>
                  <div
                    className={
                      'cota_stats_divine_influence cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setDivineInfluence(e.target.innerText)}
                  >
                    {divineInfluence}
                  </div>
                  <div
                    className={
                      'cota_stats_agility_defense_dice cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('agility')}
                    >
                      🎲
                    </span>
                  </div>
                  <div
                    className={
                      'cota_stats_agility_defense_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Agility Defense
                  </div>
                  <div
                    className={
                      'cota_stats_agility_defense cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setAgilityDefense(e.target.innerText)}
                  >
                    {agilityDefense}
                  </div>
                  <div
                    className={
                      'cota_stats_intuition_defense_dice cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('intuition')}
                    >
                      🎲
                    </span>
                  </div>
                  <div
                    className={
                      'cota_stats_intuition_defense_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    Intuition Defense
                  </div>
                  <div
                    className={
                      'cota_stats_intuition_defense cota_stats_area cota_stats_area' +
                      darkMode()
                    }
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => setIntuitionDefense(e.target.innerText)}
                  >
                    {intuitionDefense}
                  </div>
                  <div
                    className={
                      'cota_stats_inventory cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                    //onClick={() => openInventory()}
                  >
                    Inventory 🗁
                  </div>
                  <div
                    className={
                      'cota_stats_notes cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                    onClick={() => openNotes()}
                  >
                    Notes 🗁
                  </div>
                  <div
                    className={
                      'cota_stats_modifiers cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                    //onClick={() => openNotes()}
                  >
                    Modifiers ➕➖
                  </div>
                  <div
                    className={
                      'cota_stats_might_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('might')}
                    >
                      &nbsp;MIGHT 🎲
                    </span>
                  </div>
                  {[0, 1, 2, 3, 4, 5].map((i) => (
                    <div
                      key={i}
                      className={`cota_stats_rhombus cota_stats_might_${
                        i + 1
                      } ${
                        might[i]
                          ? `cota_stats_rhombus_pressed cota_stats_rhombus_pressed${darkMode()}`
                          : ''
                      }`}
                      onClick={() => handleToggleCotaHex(i, setMight, might)}
                    ></div>
                  ))}

                  <div
                    className={
                      'cota_stats_divination_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('divination')}
                    >
                      &nbsp;DIVINATION 🎲
                    </span>
                  </div>
                  {[0, 1, 2, 3, 4, 5].map((i) => (
                    <div
                      key={i}
                      className={`cota_stats_rhombus cota_stats_divination_${
                        i + 1
                      } ${
                        divination[i]
                          ? `cota_stats_rhombus_pressed cota_stats_rhombus_pressed${darkMode()}`
                          : ''
                      }`}
                      onClick={() =>
                        handleToggleCotaHex(i, setDivination, divination)
                      }
                    ></div>
                  ))}
                  <div
                    className={
                      'cota_stats_agility_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('agility')}
                    >
                      &nbsp;AGILITY 🎲
                    </span>
                  </div>
                  {[0, 1, 2, 3, 4, 5].map((i) => (
                    <div
                      key={i}
                      className={`cota_stats_rhombus cota_stats_agility_${
                        i + 1
                      } ${
                        agility[i]
                          ? `cota_stats_rhombus_pressed cota_stats_rhombus_pressed${darkMode()}`
                          : ''
                      }`}
                      onClick={() =>
                        handleToggleCotaHex(i, setAgility, agility)
                      }
                    ></div>
                  ))}
                  <div
                    className={
                      'cota_stats_intuition_label cota_stats_label cota_stats_label' +
                      darkMode()
                    }
                  >
                    <span
                      className='cota_stats_dice_roller'
                      onClick={() => rollDice('intuition')}
                    >
                      &nbsp;INTUITION 🎲
                    </span>
                  </div>
                  {[0, 1, 2, 3, 4, 5].map((i) => (
                    <div
                      key={i}
                      className={`cota_stats_rhombus cota_stats_intuition_${
                        i + 1
                      } ${
                        intuition[i]
                          ? `cota_stats_rhombus_pressed cota_stats_rhombus_pressed${darkMode()}`
                          : ''
                      }`}
                      onClick={() =>
                        handleToggleCotaHex(i, setIntuition, intuition)
                      }
                    ></div>
                  ))}
                </div>
              </div>
              <div className='cota_stats_spacer' />
            </div>
          </div>
        </span>
        <span className='cota_character_sheet_right'>
          <div className='cota_character_sheet_right_up'>
            <div className='cota_character_page_2 cota_decorative_outline'>
              <div className='cota_stats_spacer' />
              {
                /*isPhone ? (
                <div>
                  <div
                    className={
                      'cota_rp_ability_1 cota_outline cota_outline' + darkMode()
                    }
                  >
                    <CotaRPAbility
                      mode={mode}
                      id={'Universal Talent'}
                      diceBox={diceBox}
                      path={path}
                    />
                  </div>
                  <div
                    className={
                      'cota_rp_ability_2 cota_outline cota_outline' + darkMode()
                    }
                  >
                    <CotaRPAbility
                      mode={mode}
                      id={'Minor Talent'}
                      diceBox={diceBox}
                      path={path}
                    />
                  </div>
                  <div
                    className={
                      'cota_rp_ability_3 cota_outline cota_outline' + darkMode()
                    }
                  >
                    <CotaRPAbility
                      mode={mode}
                      id={'Major Talent'}
                      diceBox={diceBox}
                      path={path}
                    />
                  </div>
                  <div className='cota_consumables'>
                    <div
                      className={
                        'cota_consumable_1 cota_outline cota_outline' +
                        darkMode()
                      }
                    >
                      <CotaPotion mode={mode} id={1} diceBox={diceBox} />
                    </div>
                    <div
                      className={
                        'cota_consumable_2 cota_outline cota_outline' +
                        darkMode()
                      }
                    >
                      <CotaPotion mode={mode} id={2} diceBox={diceBox} />
                    </div>
                  </div>
                </div>
              ) : (*/
                <div className='cota_rp_abilities'>
                  <div className='cota_rp_abilities_left'>
                    <div
                      className={
                        `cota_rp_ability_1 ${
                          subpathRPAbilityPlace === '1'
                            ? 'cota_outline_subpath'
                            : 'cota_outline'
                        } cota_outline` + darkMode()
                      }
                    >
                      <CotaRPAbility
                        mode={mode}
                        id={
                          subpathRPAbilityPlace === '1'
                            ? 'Subpath Talent'
                            : 'Universal Talent'
                        }
                        diceBox={diceBox}
                        path={path}
                        subpath={subpath}
                      />
                    </div>
                    <div
                      className={
                        `cota_rp_ability_3 ${
                          subpathRPAbilityPlace === '3'
                            ? 'cota_outline_subpath'
                            : 'cota_outline'
                        } cota_outline` + darkMode()
                      }
                    >
                      <CotaRPAbility
                        mode={mode}
                        id={
                          subpathRPAbilityPlace === '3'
                            ? 'Subpath Talent'
                            : 'Major Talent'
                        }
                        diceBox={diceBox}
                        path={path}
                        subpath={subpath}
                      />
                    </div>
                  </div>
                  <div className='cota_rp_abilities_right'>
                    <div
                      className={
                        `cota_rp_ability_2 ${
                          subpathRPAbilityPlace === '2'
                            ? 'cota_outline_subpath'
                            : 'cota_outline'
                        } cota_outline` + darkMode()
                      }
                    >
                      <CotaRPAbility
                        mode={mode}
                        id={
                          subpathRPAbilityPlace === '2'
                            ? 'Subpath Talent'
                            : 'Minor Talent'
                        }
                        diceBox={diceBox}
                        path={path}
                        subpath={subpath}
                      />
                    </div>
                    <div className='cota_consumables'>
                      <div
                        className={
                          'cota_consumable_1 cota_outline cota_outline' +
                          darkMode()
                        }
                      >
                        <CotaPotion mode={mode} id={1} diceBox={diceBox} />
                      </div>
                      <div
                        className={
                          'cota_consumable_2 cota_outline cota_outline' +
                          darkMode()
                        }
                      >
                        <CotaPotion mode={mode} id={2} diceBox={diceBox} />
                      </div>
                    </div>
                  </div>
                </div>
                //)
              }
            </div>
          </div>
          <CotaScrollGrid
            mode={mode}
            declaredScrolls={declaredScrolls}
            leveledScrolls={leveledScrolls}
            openModal={openModal}
            path={path}
            subpath={subpath}
            guildTitle={characterGuild + ' ' + characterGuildTitle}
          />
        </span>
      </div>
      <CotaScroll
        mode={mode}
        show={showModal}
        close={closeModal}
        selected={selectedScroll}
        showing={showingScroll}
        declaredScrolls={declaredScrolls}
        setDeclaredScrolls={setDeclaredScrolls}
        leveledScrolls={leveledScrolls}
        setLeveledScrolls={setLeveledScrolls}
        diceBox={diceBox}
        path={path}
        subpath={subpath}
        guildTitle={characterGuild + ' ' + characterGuildTitle}
      />
      <CotaNotes mode={mode} show={showNotes} close={closeNotes} />
      <CotaInventory mode={mode} show={showInventory} close={closeInventory} />
      <CotaGuild
        mode={mode}
        show={showGuildSelect}
        close={closeGuildSelect}
        characterGuild={characterGuild}
        handleGuildChange={handleGuildChange}
        characterGuildTitle={characterGuildTitle}
        handleGuildTitleChange={handleGuildTitleChange}
      />
      <CotaPath
        mode={mode}
        show={showPathSelect}
        close={closePathSelect}
        path={path}
        handlePathChange={handlePathChange}
        subpath={subpath}
        handleSubPathChange={handleSubPathChange}
        subpathRPAbilityPlace={subpathRPAbilityPlace}
        setSubpathRPAbilityPlace={setSubpathRPAbilityPlace}
        isPathOrSubpath={pathOrSubPathSelection}
      />
    </div>
  );
}
